<template>
  <shadow-root id="shadow">
    <link rel="stylesheet" :href="css">
    <div id="onboarding" />
  </shadow-root>
</template>

<script>
import { env } from '@/store'
import Cookies from 'js-cookie'
import { registerApplication, unregisterApplication } from 'single-spa'

const mounted = function () {
  // eslint-disable-next-line no-undef
  const remoteImport = url => System.import(url)
  const token = Cookies.get(`${env}mp_tk`)

  registerApplication({
    name: 'onboarding',
    app: () => remoteImport('onboarding'),
    activeWhen: ['/'],
    customProps: {
      token,
      accountId: this.profile.merchant.account.id,
      merchantId: this.profile.merchant.id,
      domElementGetter: () => {
        return document.getElementById('shadow').shadowRoot.getElementById('onboarding')
      },
      onError: (error = []) => {
        this.setError(error[0])
      },
      onSubmit: () => {
        window.location.replace(process.env.VUE_APP_DASH_URL)
      }
    }
  })
}

const methods = {
  setError: function (error) {
    this.$store.dispatch('SET_ERROR', error)
  }
}

const beforeDestroy = () => {
  unregisterApplication('onboarding')
}

const replaceVersionEnv = env => {
  if (!env) return env
  return env.replace('v0.184.0', 'v0.142.0')
}

export default {
  name: 'OnboardingPage',
  methods,
  mounted,
  beforeDestroy,
  computed: {
    profile () {
      return this.$store.getters.profile
    },
    css () {
      return `${replaceVersionEnv(process.env.VUE_APP_ONBOARDING_URL)}/style.css`
    }
  }
}
</script>
